import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState, useMemo } from 'react';
import { Context } from '../../Context';
import { db } from '../../firebase';
import { setDoc, doc, getDoc, onSnapshot } from "firebase/firestore";

// components
import Loading from "../../components/Loading";
import Spinner from "../../components/Spinner";
import withAuthentication from "../../withAuthentication";

// Video player
import ReactPlayer from 'react-player'

// UI + Images
import { TextField, Box, Stepper, Step, StepLabel } from '@mui/material';

function Step3() {
    const { userState, loading, updateData } = useContext(Context);
    const { onboardingProcess, onboardingData, role, group, tenantId, tenantName, email, firstName, lastName, uid, program } = userState;

    const isCK = useMemo(() => {
        if (!tenantName || !program || !program[0]) return false;
        return tenantName === "CULTUUR & KUNST" || program[0] === "MDT Cultuur & Kunst";
    }, [tenantName, program]);

    const studentsStepsTitles = !isCK ? ['Teken toestemmingsverklaring', 'Beantwoord vragenlijst'] : ["Teken toestemmingsverklaring", "Onderteken overheidsdocumenten", "Bekijk video's"]
    const teachersStepsTitles = ['Teken schoolovereenkomst', 'Upload studentenlijst', 'Voeg notities toe']
    const [stepsTitles, setStepsTitles] = useState(studentsStepsTitles);
    const [message, setMessage] = useState('')
    const [teacherGroupData, setTeacherGroupData] = useState([])
    const [selectedGroup, setSelectedGroup] = useState('');
    const [teacherNotes, setTeacherNotes] = useState('')
    const [showSpinner, setShowSpinner] = useState(false);
    const [watchedVideos, setWatchedVideos] = useState({
        video1: false,
        video2: false
    });

    const videos = {
        video1: 'https://www.youtube.com/watch?v=vdab66Lds9g',
        video2: 'https://www.youtube.com/watch?v=3o9BZAZ0168'
    }

    // console.log(watchedVideos, 'watchedVideos')

    const formURL = `https://zfrmz.eu/YpqVP0ZqIwfng218OX5u?eml=${email}&fn=${firstName}&lsnm=${lastName}`
    const navigate = useNavigate();

    // styling for MUI TextField:
    const inputStyle = {
        inputLabel: {
            color: '#2f3540',
            fontSize: '1.2rem',
            fontWeight: 'bold',
        }
    }
    // check if user is teacher, and change steps titles accordingly:
    useEffect(() => {
        if (loading || !role) return
        role === 'teacher' ? setStepsTitles(teachersStepsTitles) : setStepsTitles(studentsStepsTitles)
    }, [loading, role])

    // check if user has already completed this step, and redirect accordingly:
    useEffect(() => {
        if (loading || !onboardingProcess || !onboardingData) return

        // they can't get to step 3 if they haven't completed step 2:
        if (!onboardingProcess.step2) {
            navigate('/onboarding/step2')
            return
        }
        if (onboardingProcess.step3) {
            navigate('/')
        }
    }, [loading, onboardingProcess, onboardingData])

    // grab the first group in group array and set it in state:
    useEffect(() => {
        if (loading || !group || group.length === 0) return

        // TODO: add support for multiple groups:
        setSelectedGroup(group[0])
    }, [loading, group])

    // fetch teacher's group data:
    useEffect(() => {
        if (loading || !group || group.length === 0 || !selectedGroup) return
        // fetch all groupData + sessions for the group:
        const fetchGroup = async () => {
            try {
                const groupRef = doc(db, "tenants", tenantId, "groups", selectedGroup);
                const groupSnap = await getDoc(groupRef);
                const groupData = groupSnap.data();
                if (groupSnap.exists()) {
                    setTeacherGroupData(groupData)
                }

            } catch (error) {
                console.log('error', error)
            }
        }
        fetchGroup()
    }, [group, loading, tenantId, selectedGroup]);

    // set notes/goal in state:
    const handleChange = (e) => {
        const { name, value } = e.target;
        // if user is teacher, set notes:
        if (name === 'notes') {
            setTeacherNotes(value)
            return
        }
    }

    // check if user has watched the videos:
    useEffect(() => {
        if (loading || !onboardingData) return
        if (onboardingData.watchedVideos) {
            setWatchedVideos(true)
        }
    }, [loading, onboardingData])

    const saveForm = async () => {
        if (role === 'user' && (!watchedVideos.video1 || !watchedVideos.video2)) {
            setMessage('Bekijk de video\'s voordat je verder gaat')
            return
        }

        let updatedOnboardingData = {}
        let updatedGroupData = {}
        let updatedOnboardingProcess = {}

        if (role === 'teacher') {
            updatedOnboardingData = {
                ...onboardingData,
                notes: teacherNotes,
            }
            updatedGroupData = {
                ...teacherGroupData,
                notes: teacherNotes,
            }
            updatedOnboardingProcess = {
                ...onboardingProcess,
                step3: true,
            }
        }

        else {
            updatedOnboardingData = {
                ...onboardingData,
                watchedVideos: true,
            }

            updatedOnboardingProcess = {
                ...onboardingProcess,
                step3: true,
            }
        }

        setShowSpinner(true)
        try {
            updateData('onboardingData', updatedOnboardingData)
            updateData('onboardingProcess', updatedOnboardingProcess)

            // if user is teacher, update group data:
            if (role === 'teacher') {
                const groupRef = doc(db, "tenants", tenantId, "groups", selectedGroup);
                await setDoc(groupRef, updatedGroupData);
            }
        } catch (error) {
            console.log('error', error)
            alert('Cannot save, something went wrong, please try again later:', error)
        } finally {
            setTimeout(() => {
                setShowSpinner(false)
                navigate('/')
            }, 3500)
        }
    }

    // add a listener to check if form is submitted and updated in db:
    useEffect(() => {
        if (loading || !userState || !uid || !tenantId) return
        const checkFormSubmissionStatus = async () => {
            try {
                const userRef = doc(db, 'tenants', tenantId, 'users', uid);
                // listen for real-time updated using onSnapshot():
                const unsubscribe = onSnapshot(userRef, (userSnap) => {
                    if (!userSnap.exists()) {
                        return;
                    }
                    const userData = userSnap.data();
                    if (!userData) {
                        return;
                    }
                    // look for onboardingData and onboardingProcess in userData:
                    const { onboardingProcess } = userData;
                    if (!onboardingProcess) {
                        return;
                    }
                    // check if form is submitted:
                    if (onboardingProcess && onboardingProcess.step3 === true) {
                        navigate('/')
                    }
                })
                // Clean up the listener on component unmount
                return () => {
                    unsubscribe();
                }
            } catch (error) {
                console.log(error)
            }
        }
        checkFormSubmissionStatus();

    }, [loading, userState, uid, tenantId]);

    if (loading) return <Loading />
    return (
        <div className='page-container onboarding-page'>
            <div className='onboarding-content-container'>
                {showSpinner && <Spinner message={message} />}
                <div className="onboarding-closing-icon-container">
                    <button
                        onClick={() => navigate('/')}
                        className="yellow-btn">X
                    </button>
                </div>
                <div className='onboarding-title'>
                    <h5>{role !== 'teacher' ? 'Stap 3: Waar wil jij in groeien' : 'Stap 3: Voeg opmerkingen over studenten toe'}</h5>
                    <p > {role !== 'teacher' ?
                        isCK ? "Bekijk deze twee video's over het programma om het registratieproces te voltooien" : "" :
                        "Heeft u leerlingen met specifieke behoeften of aandoeningen, zoals allergieën, handicaps of zorgvragen? Wij vinden het belangrijk om een veilige en inclusieve omgeving te garanderen voor elk kind. Als u nog iets onder onze aandacht wilt brengen, of aanvullende informatie wilt geven waarvan u denkt dat die ons zou helpen ons beter voor te bereiden op de workshop, deel dit dan alstublieft met ons in de daarvoor bestemde ruimte hieronder."}
                    </p>
                </div>
                {role && role === 'user' &&
                    <div className="video-row step3">
                        <div className="video-box"  >
                            <div className="element-title-green">
                                <h5>Wat is MDT Cultuur & Kunst?</h5>
                            </div>
                            <div className="element-body video-container" style={{ borderTopLeftRadius: '0px' }}>
                                <ReactPlayer
                                    className='react-player'
                                    url={videos.video1}
                                    onEnded={() => setWatchedVideos({ ...watchedVideos, video1: true })}
                                    controls={true} // Show video controls (play, pause, etc.)
                                />
                            </div>
                        </div>
                        <div className="video-box"  >
                            <div className="element-title-green">
                                <h5>Uitleg e-learning</h5>
                            </div>
                            <div className="element-body video-container" style={{ borderTopLeftRadius: '0px' }}>
                                <ReactPlayer
                                    className='react-player'
                                    url={videos.video2}
                                    onEnded={() => setWatchedVideos({ ...watchedVideos, video2: true })}
                                    controls={true} // Show video controls (play, pause, etc.)
                                />
                            </div>
                            <a href={'http://www.mdtcultuurenkunst.nl/team/'} target="_blank" rel="noreferrer">
                                <button className="yellow-btn">Klik als je durft</button>
                            </a>
                        </div>
                    </div>
                }

                {role && role === 'teacher' &&
                    <div className='onboarding-body teacher'>
                        <div className='onboarding-input-container teacher'>
                            <TextField
                                name="notes"
                                value={teacherNotes || ''}
                                multiline
                                fullWidth
                                rows={5}
                                onChange={handleChange}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                    style: inputStyle.inputLabel
                                }}
                                placeholder="Bijvoorbeeld: ik denk dat deze student erg gemotiveerd is en veel potentieel heeft. Ik denk dat hij/zij baat zou hebben bij een mentor die erg gestructureerd is en hem/haar kan helpen met plannen en het stellen van doelen."
                            />
                        </div>
                    </div>
                }
                <p className="successMessage">{message}</p>

                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={2} alternativeLabel>
                        {stepsTitles && stepsTitles.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

            </div>
            <div className="bottomNavigation" style={{ justifyContent: 'space-between' }}>
                <button onClick={() => navigate('/onboarding/step2')}>Terug naar stap 2</button>
                <button
                    disabled={role === 'user' && (!watchedVideos.video1 || !watchedVideos.video2)}
                    onClick={saveForm} >
                    Sla op en voltooi registratie
                </button>

            </div>
        </div>

    )
}
export default withAuthentication(Step3);