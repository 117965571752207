import { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { db } from '../firebase';
import { collection, getDocs } from "firebase/firestore";
import withAuthentication from "../withAuthentication";
import { Context } from "../Context";
import ReactPlayer from 'react-player'

// components
import Loading from "../components/Loading";
import useScreenSize from '../components/useScreenSize';

// UI + Images
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, TextField, } from "@mui/material"
import ckBGDesktop from '../images/ckBGDesktop.png';
import ckBGTablet from '../images/ckBGTablet.png';
import ckBGMobile from '../images/ckBGMobile.png';

function General() {
    const { userState, loading } = useContext(Context);
    const { program, tenantId, role, tenantName } = userState;
    const [selectedProgram, setSelectedProgram] = useState(program);
    const [programsData, setProgramsData] = useState([]);
    const [allProgramsDropdown, setAllProgramsDropdown] = useState([]);
    const navigate = useNavigate();

    const isCK = tenantName === "CULTUUR & KUNST" || (program && program[0] === 'MDT Cultuur & Kunst')
    const screenSize = useScreenSize(); // 'mobile', 'tablet', or 'desktop'

    const getBackgroundImage = () => {
        switch (screenSize) {
            case 'mobile':
                return ckBGMobile;
            case 'tablet':
                return ckBGTablet;
            default:
                return ckBGDesktop;
        }
    };

    const ckBackgroundStyle = {
        backgroundImage: `url(${getBackgroundImage()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }

    // fetch programs from firestore
    useEffect(() => {
        if (loading || !tenantId || !role || !program) return;
        // if teacher/admin/superAdmin fetch all programs, if student fetch only the student's program:

        const fetchAllPrograms = async () => {
            const programsRef = collection(db, 'tenants', tenantId, 'programs');
            try {
                const programSnapshot = await getDocs(programsRef);
                if (programSnapshot.empty) {
                    console.log('No matching documents.');
                    return;
                }
                const programs = programSnapshot.docs.map(doc => {
                    const programData = doc.data();
                    return {
                        uid: doc.id,
                        ...programData
                    };
                });
                // all programs from firestore
                setProgramsData(programs);

                if (role === 'user' || role === 'teacher') {
                    const userProgram = program[0]
                    const selectedProgramData = programs.find(program => program.name === userProgram);
                    setSelectedProgram(selectedProgramData);
                    return;

                }
                setSelectedProgram(programs[0]);
            } catch (err) {
                console.error("Error fetching programs data:", err);
            }
        };
        fetchAllPrograms();

    }, [loading, tenantId, role, program]);

    // set all programs to a dropdown:
    useEffect(() => {
        if (loading || !programsData || !programsData || role === 'user') return;
        // check if programsData is an array, if not, exit
        if (!Array.isArray(programsData)) return;
        const allProgramsDropdown = programsData.map((program, index) => {
            return (
                <MenuItem key={index} value={program.name}>
                    {program.name}
                </MenuItem>
            );
        });
        setAllProgramsDropdown(allProgramsDropdown);

    }, [loading, programsData, role]);

    if (loading) return <Loading />

    return (
        <div className={`page-container ${isCK ? 'bg-overlay' : ''}`} style={isCK ? ckBackgroundStyle : {}}>
            <div className='page-title'>
                <h3 style={{ color: isCK ? 'white' : '' }} >Informatie over {selectedProgram ? selectedProgram.name : program[0]}</h3>
                <h5 style={{ color: isCK ? 'white' : '' }}>Algemene informatie over je programma en de meest gestelde vragen</h5>
            </div>
            <div className='page-content general'>

                <div >
                    {role && role === 'admin' && programsData.length > 1 ?
                        <TextField
                            value={selectedProgram.name || ''}
                            label="Verander programma"
                            data-testid='general-2'
                            style={{ borderRadius: '10px', width: '30%', margin: '15px', backgroundColor: isCK ? 'white' : '', padding: isCK ? '10px' : '' }}
                            select
                            InputLabelProps={{
                                style: { padding: isCK ? '10px' : '' }
                            }}
                            onChange={(e) => {
                                const programData = programsData.find(program => program.name === e.target.value);
                                setSelectedProgram(programData);
                            }}
                        >
                            {allProgramsDropdown}
                        </TextField>
                        : ''}
                    {((role && role === 'admin') || (role && role === 'superAdmin')) && !selectedProgram.generalPage ?
                        <div style={{ marginTop: '20px', height: '200px' }} className="element-body">
                            <h6>Please add content to your program in order for it to show up here.</h6>
                        </div>
                        : ''
                    }
                </div>
                {/* general page video - if exists */}
                <div className="general-box-row">
                    <div className="general-box-col">
                        <div data-testid='general-3' className="element-box video" style={{ gap: '40px' }}>
                            {selectedProgram && selectedProgram.generalPage?.videos && selectedProgram.generalPage?.videos?.map((video, index) => {
                                return (
                                    <div className="video-box"  >
                                        <div className="element-title-green">
                                            <h5>{video.title || ''}</h5>
                                        </div>
                                        <div className="element-body video-container" style={{ borderTopLeftRadius: '0px' }}>
                                            <ReactPlayer
                                                className='react-player'
                                                url={video && video?.url ? video.url : ''}
                                                controls={true} // Show video controls (play, pause, etc.)
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </div>
                    </div>
                </div>
                <div className="general-box-row">
                    <div className="general-box-col">
                        <div data-testid='general-3' className="element-box" >
                            {selectedProgram && selectedProgram.generalPage?.content && selectedProgram.generalPage?.content?.map((item, index) => {
                                return (
                                    <div key={index} style={{ marginBottom: '20px' }}>
                                        <div className="element-title-green">
                                            <h5 >{item.title}</h5>
                                        </div>
                                        <div className="text-section element-body" style={{ borderTopRightRadius: '0%' }}>
                                            <p >{item.text}</p>
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </div>
                    </div>
                </div>
                <div className="general-box-row">
                    <div className="general-box-col col2">
                        <div data-testid='general-4' style={{ gap: '0px' }} className='element-box' >
                            {program && selectedProgram && selectedProgram.generalPage && selectedProgram?.generalPage?.questions &&
                                <div className="element-title-green">
                                    <h5>Q&A: meestgestelde vragen</h5>
                                </div>
                            }
                            {program && selectedProgram && selectedProgram.generalPage?.questions && selectedProgram.generalPage?.questions?.map((item, index) => {
                                return (
                                    <Accordion
                                        key={index}
                                        defaultExpanded={index === 0 ? true : false}
                                        sx={{
                                            width: '100%',
                                            border: 'var(--border)',
                                            boxShadow: 'none',
                                            marginBottom: '10px',
                                            borderRadius: index === 0 ? '0px 10px 10px 10px' : '10px', // Straight top for the first box
                                            '&:before': {
                                                height: '0px',
                                            },
                                            '&.Mui-expanded': {
                                                margin: '0 0 10px 0',
                                            },
                                            '&:last-of-type': {
                                                borderRadius: '10px',  // Ensure the last accordion has rounded corners
                                            },
                                        }}


                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="qaQuestion-content"
                                            id="qaQuestion-header"
                                            sx={{
                                                borderRadius: '10px',  // Also round the edges of the summary section
                                            }}
                                        >
                                            <Typography style={{ fontWeight: '500' }} > {item.question}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            sx={{
                                                borderRadius: '10px',  // Ensure rounded corners in details as well
                                            }}
                                        >
                                            <Typography>{item.answer}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="pagination">
                <button onClick={() => navigate(-1)}>
                    <i className="fas fa-arrow-left"></i>
                    Terug
                </button>
            </div>
        </div >

    )
}
export default withAuthentication(General);