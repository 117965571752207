import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from 'react';
import { storage } from '../../firebase';
import { db } from '../../firebase';
import { Context } from '../../Context';
import { doc, getDoc } from "firebase/firestore";
import Loading from "../../components/Loading";
import Spinner from "../../components/Spinner";
import withAuthentication from "../../withAuthentication";
import { Box, Stepper, Step, StepLabel, Radio, RadioGroup, FormControl, FormControlLabel, TextField, MenuItem } from '@mui/material';
import Papa from 'papaparse';
import { ref, uploadBytes } from "firebase/storage";

import { Tooltip } from 'react-tooltip'

function Step2() {
    const { userState, loading, updateData } = useContext(Context);
    const { uid, onboardingProcess, onboardingData, role, group, program, tenantId, tenantName } = userState;

    const isCK = tenantName === "CULTUUR & KUNST" || (program && program[0] === 'MDT Cultuur & Kunst')
    const [selectedGroup, setSelectedGroup] = useState(group[0] || '');
    const [isDisabled, setIsDisabled] = useState(true);
    const [answeredQuestionaire, setAnsweredQuestionaire] = useState('');
    const studentsStepsTitles = !isCK ? ['Teken toestemmingsverklaring', 'Beantwoord vragenlijst'] : ["Teken toestemmingsverklaring", "Onderteken overheidsdocumenten", "Bekijk video's"]
    const teachersStepsTitles = ['Teken schoolovereenkomst', 'Upload studentenlijst', 'Voeg notities toe']
    const [stepsTitles, setStepsTitles] = useState(studentsStepsTitles);
    const [file, setFile] = useState(null); // file object
    const [fileName, setFileName] = useState(''); // .csv file name
    const [message, setMessage] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);

    const navigate = useNavigate();

    // when page loads, grab updated onboardingProcess from db:
    useEffect(() => {
        if (loading || !uid || !tenantId || !role) return
        const onboardingData = async () => {
            const userRef = doc(db, 'tenants', tenantId, 'users', uid);
            try {
                const userSnap = await getDoc(userRef);
                const userData = userSnap.data();
                const onboardingProcess = userData.onboardingProcess;
                if (!onboardingProcess.step1) {
                    // step 1 not answered yet, navigating to step 1'
                    navigate('/onboarding/step1')
                }
                if (onboardingProcess.step2) {
                    //step 2 already answered, if teacher, navigating to step 3, if student, check if student is C&K, if not navigate to home:
                    if (role === 'user') {
                        if (!isCK) {
                            navigate('/')
                        } else {
                            navigate('/onboarding/step3')
                        }
                    }
                    role === 'teacher' ? navigate('/onboarding/step3') : navigate('/')
                }
            } catch (error) {
                console.log('cant get onboarding data in step2', error)
            }
        }
        onboardingData()
    }, [loading, uid, tenantId, role])

    // change steps titles according to user role:
    useEffect(() => {
        if (loading || !userState || !onboardingProcess || !onboardingData) return
        role === 'teacher' ? setStepsTitles(teachersStepsTitles) : setStepsTitles(studentsStepsTitles)
    }, [loading, userState, role, onboardingProcess, onboardingData])


    // handle teacher file upload:
    const handleFileUpload = async (event) => {
        setMessage('')

        // if nothing is selected, return:
        if (!event.target.files || event.target.files.length === 0) {
            setFile(null);
            return;
        }
        // validate file:
        const fileFromUser = event.target.files[0]; // get the file
        const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
        const fileName = fileFromUser.name.toLowerCase();

        // Check if file is too big:
        if (fileFromUser.size > MAX_SIZE) {
            alert('Bestand is te groot. Upload een bestand dat kleiner is dan 5 MB');
            setFile(null);
            return;
        }

        // Check if the file is a CSV
        if (!fileName.endsWith('.csv') && !fileName.endsWith('.xlsx') && !fileName.endsWith('.xls')) {
            alert('Upload een geldig .CSV- of Excel-bestand');
            setFile(null);
            return;
        }


        // parse part of the file and check if it's a valid CSV file:
        try {
            // parse top 10 lines of the file:

            Papa.parse(fileFromUser, {
                header: true,
                skipEmptyLines: true,
                dynamicTyping: true,
                transformHeader: (header) => header.trim(),
                transform: (value) => {
                    if (typeof value === "string") {
                        // Trim the value and convert empty strings to null
                        value = value.trim();
                        return value === "" ? null : value;
                    }
                    return value;
                },
                delimiter: '',  // Auto-detect delimiter
                encoding: "UTF-8",
                newline: '\r\n',
                preview: 10,
                complete: (results) => {
                    if (results.errors.length) {
                        alert('fout bij het verwerken van het bestand. Controleer of het een .CSV-bestand is.');
                        console.error('Error parsing CSV:', results.errors);
                        setFile(null);
                        return;
                    }
                    // Filter out rows where all values are null, undefined, or empty strings
                    const filteredData = results.data.filter(row => {
                        return Object.values(row).some(value => value !== null && value !== '');
                    });

                    // Check if the CSV file is empty
                    if (filteredData.length === 0) {
                        alert('Het CSV-bestand is leeg. Controleer het bestand en probeer het opnieuw.');
                        console.log('Empty CSV file');
                        setFile(null);
                        setFileName('');
                        return;
                    }
                    // Check if the CSV file has the required headers
                    const expectedHeaders = ['email', 'firstName', 'lastName'];
                    const uploadedHeaders = Object.keys(results.data[0]);
                    const missingHeaders = expectedHeaders.filter(header => !uploadedHeaders.includes(header));

                    if (missingHeaders.length > 0) {
                        setMessage('')
                        setFile('');
                        setFileName('');
                        console.error('Missing required headers:', missingHeaders);
                        alert(`Ontbrekende vereiste headers in het CSV-bestand: ${missingHeaders.join(', ')},
                         Controleer het bestand en probeer het opnieuw`);
                        return;
                    }


                    // All checks passed, adding two columns "program" and "group"
                    const modifiedData = filteredData.map(row => {
                        return {
                            ...row,
                            program: program,
                            group: group,
                            role: 'user'
                        }
                    })
                    // Unparse the modified data to CSV
                    const csv = Papa.unparse(modifiedData, { newline: "\r\n" });

                    // Create a new File object with the clean data
                    const file = new File([csv], `${fileName}`, { type: 'text/csv' });

                    // Set the file in state to be uploaded
                    setFile(file);
                    setFileName(file.name);
                }
            });
        } catch (error) {
            console.log(error)
            setMessage('Fout bij het verwerken van het bestand')
        } finally {
            event.target.value = null;
            setMessage('')
        }
    }

    // upload .csv from teacher to storage:
    const uploadFile = async () => {
        if (loading || !file || !group || !program) {
            alert('U moet een bestand selecteren');
            return;
        }

        setShowSpinner(true)
        try {
            // upload file to storage:
            setMessage('Bestand uploaden...')
            const timestamp = Date.now();
            const newFileName = `${timestamp}-${program}-${selectedGroup}-${fileName}`;
            const storageRef = ref(storage, `${tenantId}/studentLists/pending/${newFileName}`);

            await uploadBytes(storageRef, file);
            setFileName('')
            setFile(null);
            setMessage('Bestand verzonden')
            setIsDisabled(false)

        } catch (error) {
            console.error('Error during file upload:', error.message || error);
            setMessage('Er is een fout opgetreden. Probeer het opnieuw.')
            alert('Er is een fout opgetreden. Probeer het opnieuw.');
        } finally {
            setTimeout(() => {
                setMessage('')
            }, 5000)
            setShowSpinner(false)
        }
    }

    // handle radio button change confirming student answered the questionaire:
    const handleChange = (event) => {
        event.preventDefault();
        const { value } = event.target;
        // console.log(value, 'answeredQuestionaire')
        setAnsweredQuestionaire(value);
        if (value === 'Yes') {
            setIsDisabled(false)
        } else
            if (value === 'No') {
                setIsDisabled(true)
            }
    };

    // open questionaire in new tab:
    const openQuestionaire = () => {
        // remind user they need to come back after answering the questionaire:
        alert('Kom na het beantwoorden van de vragenlijst terug naar deze pagina')
        // open questionaire in new tab:
        window.open('https://survey.kantar.nl/MDTjongerenvragenlijst', '_blank', 'noopener,noreferrer')
    }

    const saveStep2 = async () => {
        // if role is user, confirm with student they have signed the questionaire:
        if (role === 'user') {
            const confirmed = window.confirm(
                'Zorg ervoor dat u op de bovenstaande knop heeft geklikt en de vragenlijst van de overheid heeft ingevuld voordat u doorgaat naar de volgende stap 🙏\n\nHeeft u de vragenlijst ingevuld?'
            );

            if (!confirmed) {
                // If the user clicks "Cancel", stop the process
                return;
            }

        }

        const updateOnboardingProcess = {
            ...onboardingProcess,
            step2: true,
        }

        try {
            await updateData('onboardingProcess', updateOnboardingProcess)
            if (role !== 'user' || isCK) {
                navigate('/onboarding/step3')
            } else {
                navigate('/')
            }
        } catch (error) {
            setMessage('Cannot save data', error)
            console.log('cant save step 2:', error)
        }
    }


    if (loading) return <Loading />
    return (
        <div className='page-container onboarding-page'>
            <div className='onboarding-content-container'>
                <div style={{ textAlign: 'left' }}>
                    <button onClick={() => navigate('/')} style={{ padding: '8px' }} className="yellow-btn">X</button>
                </div>
                <div className='onboarding-title '>
                    <h5>Stap 2: {role === 'teacher' ? 'Upload studentenlijst' : 'beantwoorden verplichte vragenlijst'}</h5>
                    {/* <p> {role === 'teacher' ? 'Upload een lijst met de namen van de studenten die aan dit project gaan werken.' : ''}</p>
                    <p> {role === 'teacher' ? 'Download de sjabloon naar uw computer en vul het bestand in. Wijzig alstublieft geen van de kolommen (CSV). Upload het bestand als u klaar bent' : ''}</p> */}
                </div>
                <div className="onboarding-top-teacher">
                    {role && role === 'teacher' &&
                        <>
                            <div className="onboarding-top-buttons-teacher"  >
                                <p>De studentenlijst al naar je MDT-manager gestuurd?</p>
                                <button
                                    className="transparentBtn"
                                    style={{ textDecoration: 'underline', }}
                                    onClick={saveStep2}
                                >Stap overslaan
                                </button>
                            </div>
                            {group && group.length > 1 &&
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                    <TextField
                                        select
                                        label="Selecteer groep"
                                        disabled={group.length <= 1}
                                        value={selectedGroup || ''}
                                        onChange={(e) => setSelectedGroup(e.target.value)}
                                        variant="outlined"
                                        sx={{ width: '200px', }}
                                    >
                                        {group.map((groupName, index) => {
                                            return <MenuItem key={index} value={groupName}>{groupName}</MenuItem>
                                        })}
                                    </TextField>

                                </div>
                            }
                        </>
                    }
                </div>
                <div style={{ alignItems: 'center' }} className='onboarding-body'>
                    {message && <p className="successMessage">{message}</p>}
                    <div className='onboarding-section'>
                        {showSpinner && <Spinner />}

                        {role && role === 'teacher' &&
                            <div className="onboarding-body teacher"  >
                                <div className='onboarding-section-card'>
                                    <h6>1. Download sjabloon</h6>
                                    <p>Vul de informatie van de studenten in. <strong>Verander de koppen van het bestand niet. </strong></p>
                                    <div className="onboarding-section-downloadbtn">
                                        <a href="https://www.mdtportaal.nl/templates/students-template-windows.csv"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <button  >Windows
                                                <i className="fas fa-download"></i>
                                            </button>

                                        </a>
                                        <a href="https://www.mdtportaal.nl/templates/students-template.csv"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <button  >Mac
                                                <i className="fas fa-download"></i>
                                            </button>

                                        </a>

                                    </div>
                                    {/* <p>* info voor Windows-gebruikers
                                        <i
                                            style={{ marginLeft: '10px' }}
                                            data-tooltip-id="windows-users"
                                            className="info-icon fas fa-info-circle"

                                            data-tooltip-content=
                                            "Open het bestand in Excel. Vul de gegevens in. Klik op 'Bestand' > 'Opslaan als'. Kies bij 'Opslaan als' voor 'CSV UTF-8 (door komma's gescheiden) (*.csv)'. Klik op 'Opslaan' en bevestig eventuele waarschuwingen."
                                        ></i>
                                    </p>
                                    <Tooltip style={{ maxWidth: '300px' }} id="windows-users" /> */}


                                </div>

                                <div className='onboarding-section-card'>
                                    <h6>2. Upload voltooid bestand</h6>
                                    <p >Als u klaar bent met de studentenlijst, selecteer het bestand vanaf uw computer</p>
                                    <div style={{ marginTop: '10px' }}>
                                        <input
                                            type="file"
                                            id="fileUpload"
                                            name="filename"
                                            accept=".csv,.xlsx,.xls"
                                            onChange={handleFileUpload}
                                            style={{ display: 'none', }}
                                        /><label
                                            htmlFor="fileUpload"
                                            className="teachers-file-upload"
                                        >Selecteer bestand
                                            <i className="fas fa-upload"></i>
                                        </label>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            marginTop: '15px',
                                        }}>

                                            <p style={{ fontSize: '0.95rem' }}>
                                                {fileName ? fileName : 'Geen bestand geselecteerd'}
                                            </p>
                                            {fileName &&
                                                <button
                                                    onClick={() => { setFileName(''); setFile(null) }}
                                                    className='transparentBtn'
                                                    style={{ fontSize: '0.95rem', textDecoration: 'underline' }}>
                                                    Verwijder bestand
                                                </button>
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className='onboarding-section-card '>
                                    <h6>3. Stuur het bestand</h6>
                                    <p>We zullen de informatie verwerken en laten het u weten als er iets ontbreekt</p>
                                    <button
                                        id="uploadBtn"
                                        disabled={!file}
                                        onClick={uploadFile}
                                    >Stuur bestand
                                        < i className="fas fa-paper-plane"></i></button>
                                    {message && <p className="successMessage">{message}</p>}
                                </div>

                            </div>

                        }
                        {role && role !== 'teacher' &&
                            <div>
                                <div className='onboarding-section'>
                                    <h6>1. vragenlijst van de overheid invullen</h6>
                                    <p>Voordat u aan uw project kunt beginnen, moet u een aantal vragen beantwoorden.
                                        Deze vragen zijn vereist door de overheid en worden gebruikt om te bepalen of
                                        je in aanmerking komt om aan dit project te werken. Het is belangrijk dat u alle
                                        vragen naar waarheid en naar uw beste weten beantwoordt.
                                        <span style={{ fontWeight: '500' }}> Laat deze pagina open en kom erop terug als je klaar bent. </span>
                                    </p>
                                    <button
                                        onClick={openQuestionaire}
                                        style={{ width: '300px', margin: '20px 5px' }}
                                        className='yellow-btn'>Vragenlijst van de overheid invullen
                                    </button>
                                </div>
                                <div className='onboarding-section'>
                                    <h6>2. Bevestig ondertekening</h6>
                                    <p>Heeft u de verplichte vragenlijst beantwoord?</p>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="answeredQuestions-label"
                                            defaultValue="No"
                                            style={{ flexDirection: 'row' }}
                                            name="didAnsweredQuestions"
                                            onChange={handleChange}
                                            value={answeredQuestionaire || "No"}
                                        >
                                            <FormControlLabel value="No" control={<Radio />} label="Nee" />
                                            <FormControlLabel value="Yes" control={<Radio />} label="Ja" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                        }
                    </div>

                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={1} alternativeLabel>
                            {stepsTitles.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </div>
            </div>

            <div className="bottomNavigation">
                {/* <button onClick={() => navigate('/onboarding/step1')}>Terug naar stap 1</button> */}
                <button disabled={isDisabled} onClick={saveStep2} >Volgende</button>
            </div>
        </div>
    )
}

export default withAuthentication(Step2);