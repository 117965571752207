import { createContext, useState, useEffect, useRef } from "react";
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db, auth } from './firebase';
import Loading from './components/Loading';

// Creating context to manage state for all components and pages
const Context = createContext();

// Creating the provider to wrap the root
function ContextProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const tenantIdRef = useRef(null);
    const [isTenantIdSet, setIsTenantIdSet] = useState(false);

    // global state for all components
    const [userState, setUserState] = useState({
        uid: '',
        tenantId: '',
        tenantName: '',
        createdAt: '',
        role: '',
        firstName: '',
        lastName: '',
        program: [],
        group: [],
        session: '',
        email: '',
        displayName: '',
        onboardingProcess: {
            step1: false,
            step2: false,
            step3: false,
        },
        onboardingData: {
            signed: false,
            signedDate: '',
        },
        questionnaires: [],
        completedQuizzes: [],
        completedVideos: [],
        hasChangedPassword: '',
        isSuperAdmin: '',
    });


    // observer to check if user is logged in and if so, check if they are an admin
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {

                try {
                    const idTokenResult = await user.getIdTokenResult();
                    tenantIdRef.current = idTokenResult.claims.tenantId;

                    // set tenantId in state
                    setUserState(prevState => ({ ...prevState, tenantId: tenantIdRef.current }));

                    setIsTenantIdSet(true);
                    // Confirm the user is an Admin:
                    if (!!idTokenResult.claims.admin) {
                        // console.log('User is an admin');
                        setUserState(prevState => ({
                            ...prevState,
                            onboardingProcess: {
                                step1: true,
                                step2: true,
                                step3: true,
                            },
                            uid: user.uid,
                            role: 'admin',
                        }));
                        if (!!idTokenResult.claims.superAdmin) {
                            setUserState(prevState => ({
                                ...prevState,
                                isSuperAdmin: true,
                            }));
                        }
                    } else if (!!idTokenResult.claims.teacher) {
                        setUserState(prevState => ({
                            ...prevState,
                            uid: user.uid,
                            role: 'teacher',
                        }));
                    }
                    else {
                        setUserState(prevState => ({
                            ...prevState,
                            uid: user.uid,
                            role: 'user',
                        }));
                    }

                } catch (err) {
                    console.log(err.message);
                }
            } else {
                // user is logged out
                setUserState({ uid: null, role: '', tenantId: '' });
            }
            setLoading(prevLoading => false); // indicate that data has finished loading
        });
        // Clean up the observer on component unmount
        return () => unsubscribe();
    }, []);


    // Get data from DB and load to state 
    useEffect(() => {
        if (!userState.uid || !tenantIdRef.current) {
            return
        }

        const getUserData = async () => {
            const userRef = doc(db, 'tenants', tenantIdRef.current, 'users', userState.uid);
            try {
                const userSnap = await getDoc(userRef);

                if (userSnap.exists()) {
                    const userData = userSnap.data();
                    const dataFields = ['firstName', 'lastName', 'program', 'group', 'email', 'phone', 'address', 'city', 'displayName', 'onboardingProcess', 'onboardingData', 'questionnaires', 'goal', 'completedQuizzes', 'completedVideos', 'hasChangedPassword', 'session', 'tenantName', 'isSuperAdmin', 'createdAt'];
                    const newState = {};

                    dataFields.forEach(field => {
                        if (field === 'onboardingProcess') {

                            if (userState.role === 'admin') {
                                newState[field] = {
                                    step1: true,
                                    step2: true,
                                    step3: true,
                                };
                                return;
                            }
                        }
                        if (field === 'onboardingData') {

                            if (userState.role === 'admin') {
                                newState[field] = {
                                    signed: true,
                                    signedDate: 'date',
                                };
                                return;
                            } else if (userData.role === 'teacher') {
                                newState[field] = {
                                    signed: true,
                                    signedDate: 'date',
                                    notes: '',
                                };
                                return;
                            }
                        }
                        if (field === 'goal') {
                            newState[field] = userData[field] || {
                                option1: {
                                    answer1: '',
                                    answer2: '',
                                    answer3: '',
                                },
                                option2: {
                                    answer1: '',
                                    answer2: '',
                                    answer3: '',
                                }
                            };
                            return;
                        }
                        if (field === 'group') {
                            newState[field] = userData[field] || [];
                            return;
                        }
                        if (field === 'program') {
                            newState[field] = userData[field] || [];
                            return;
                        }
                        if (field === 'hasChangedPassword') {
                            newState[field] = userData[field] || false;
                            return;
                        }
                        if (field === 'completedVideos') {
                            newState[field] = userData[field] || [];
                            return;
                        }
                        if (field === 'completedQuizzes') {
                            newState[field] = userData[field] || [];
                            return;
                        }
                        newState[field] = userData[field] || '';

                    });
                    setUserState((prevState) => ({ ...prevState, ...newState }));
                }
                if (!userSnap.exists()) {
                    console.log('cant fetch user doc from db');
                    setError('Cannot find user');
                    setLoading(false); // indicate that data has finished loading
                    return;
                }


            } catch (err) {
                console.log("Error getting document from DB: ", err.message);
                setError('Cannot find user');
            }

            setLoading(false); // indicate that data has finished loading
        };
        if (userState.uid && tenantIdRef.current) {
            getUserData();
        } else {
            setLoading(false); // if user is not logged in, indicate that data has finished loading
        }
    }, [userState.uid, userState.role, isTenantIdSet]);


    // Update data in DB and state 
    const updateData = async (field, data) => {
        const uid = userState.uid

        try {
            if (!uid) {
                console.log('no uid found', uid);
                return
            }
            const docRef = doc(db, 'tenants', tenantIdRef.current, 'users', userState.uid);

            await setDoc(docRef, { [field]: data }, { merge: true });

            setUserState(prevState => ({ ...prevState, [field]: data }));
            // console.log(`New ${field} created/updated with data: `, data);

        } catch (err) {
            console.error("Error updating document in DB: ", err.message);
        }
    }

    return (
        <Context.Provider value={{ userState, setUserState, updateData, loading, setLoading, error }}>
            {loading ? <Loading /> : children}
        </Context.Provider>
    );
}

export { ContextProvider, Context }
